<template>
  <div class="container">
    <footer class="pie-pagina">
      <!--<div class="grupo-1">
        <div class="box">
          <figure>
            <a href="/">
              <img src="../assets/logo4q.png" alt="Logo4Q" />
            </a>
          </figure>
        </div>
        
        <div class="box">          
        </div>
        <div class="box" >
          <h2 class="h2-text">SIGUENOS</h2>
          <div class="red-social">
            <a href="#" class="fa fa-facebook"></a>
            <a href="#" class="fa fa-instagram"></a>            
          </div>
        </div>        
      </div>-->
      <div class="grupo-2">
        <small
          >&copy; 2022 - 2023 <b>Servicios Gráficos 4Q ® 2.0.1</b> - Todos los Derechos
          Reservados.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterPrincipal",
  setup() {},
};
</script>

<style>
.pie-pagina {
  width: 100%;
  background-color: #e3f2fd;
}

.pie-pagina .grupo-1 .box figure {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pie-pagina .grupo-1 .box figure img {
  width: 80px;
}
.pie-pagina .grupo-1 .box h2 {
  color: rgb(11, 10, 10);
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 17px;
}
.pie-pagina .grupo-1 .box p {
  color: #efefef;
  margin-bottom: 10px;
}
.pie-pagina .grupo-1 .red-social a {
  display: inline-block;
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  margin-right: 17px;
  background-color: #0d2033;
  text-align: center;
  transition: all 300ms ease;
}
.pie-pagina .grupo-1 .red-social a:hover {
  color: aqua;
}
.pie-pagina .grupo-2 {
  background-color: #0a1a2a;
  padding: 15px 10px;
  text-align: center;
  color: #fff;
}
.pie-pagina .grupo-2 small {
  font-size: 15px;
}

@media (max-width: 576px) {
  .pie-pagina .grupo-1 {
    width: 75%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    padding: 20px 0px;
    max-width: 576px;
    margin: auto;
    display: grid;
    text-align: center;
  }
  .grupo-2 {
    font-size: 50%;
  }
  .red-social {
    margin-left: 20px;
  }
  .h2-text {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .pie-pagina .grupo-1 {
    width: 75%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    padding: 20px 0px;
    max-width: 576px;
    margin: auto;
    display: grid;
    text-align: center;
  }
  .grupo-2 {
    font-size: 50%;
  }
  .red-social {
    margin-left: 20px;
  }
  .h2-text {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .pie-pagina .grupo-1 {
    width: 100%;
    max-width: 768px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
    padding: 10px 0px;
  }
}

@media (min-width: 992px) {
  .pie-pagina .grupo-1 {
    width: 100%;
    max-width: 9920px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 170px;
    padding: 10px 0px;
  }
}

@media (min-width: 1200px) {
  .pie-pagina .grupo-1 {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 225px;
    padding: 10px 0px;
  }
}
</style>