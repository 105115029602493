 <template>
  <div class="container slide-principal">
    <!--Carousel Wrapper-->
    <div
      id="carousel-example-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <!--Indicators-->
      <ol class="carousel-indicators">
        <li
          data-target="#carousel-example-2"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carousel-example-2" data-slide-to="1"></li>
        <li data-target="#carousel-example-2" data-slide-to="2"></li>
      </ol>
      <!--/.Indicators-->
      <!--Slides-->
        <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <div class="view">
            <img
              class="d-block w-100"
              src="img/portada/portada-2.jpg"              
              width="620px"
              height="450px"
              alt="First slide"
            />
            <div class="mask rgba-black-light"></div>
          </div>
          <div class="carousel-caption">
            <h3 class="h3-responsive">Servicios Gráficos 4Q</h3>
            <p class="p-responsive">
              Somos una empresa familiar comprometida con entregar un servicio
              de calidad que cumpla con sus necesidades.
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <!--Mask color-->
          <div class="view">
            <img
              class="d-block w-100"
              src="img/portada/portada-3.jpeg"
              width="620px"
              height="450px"
              alt="Second slide"
            />
            <div class="mask rgba-black-strong"></div>
          </div>
          <div class="carousel-caption">
            <h3 class="h3-responsive">Servicios Gráficos 4Q</h3>
            <p class="p-responsive">Cuaderno Profesor</p>
          </div>
        </div>
        <div class="carousel-item">
          <!--Mask color-->
          <div class="view">
            <img
              class="d-block w-100"
              src="img/portada/portada-1.jpg"
              width="620px"
              height="450px"
              alt="Third slide"
            />
            <div class="mask rgba-black-slight"></div>
          </div>
          <div class="carousel-caption">
            <h3 class="h3-responsive">Servicios Gráficos 4Q</h3>
            <p class="p-responsive">
              Productos
            </p>
          </div>
        </div>
      </div>
      <!--/.Slides-->
      <!--Controls-->
      <a
        class="carousel-control-prev"
        href="#carousel-example-2"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carousel-example-2"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
      <!--/.Controls-->
    </div>
    <!--/.Carousel Wrapper-->
  </div>
</template>
<script>
export default {
  name: "CarouselPrincipal",
};
</script>

  <style>
.slide-principal {
  margin-bottom: 50px;
}

@media (max-width: 576px) {
  .h3-responsive {
    text-align: center;
    font-size: 125%;
  }
  .p-responsive {
    font-size: 65%;
  }
}

@media (min-width: 768px) {
  .h3-responsive {
    text-align: center;
    font-size: 150%;
  }
  .p-responsive {
    font-size: 85%;
  }
}

@media (min-width: 992px) {
  .h3-responsive {
    text-align: center;
    font-size: 200%;
  }
  .p-responsive {
    font-size: 120%;
  }
}

@media (min-width: 1200px) {
  .h3-responsive {
    text-align: center;
    font-size: 250%;
  }
  .p-responsive {
    font-size: 120%;
  }
}
</style>