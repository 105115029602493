import Vue from 'vue'
import Vuex from 'vuex'
import medidas from '../static/medidas.json'
import espiral from '../static/espiral.json'
import papel from '../static/papel.json'
import producto from '../static/producto.json'
import cuaderno from '../static/cuaderno.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    medida: medidas,
    espiral: espiral,
    papel: papel,
    producto: producto,
    cuaderno: cuaderno,
  },
  getters: {},
  mutations: {
    setMedida: (state, medida) => {
      state.medida = medida;
    },
    setEspiral: (state, espiral) => {
      state.espiral = espiral;
    },
    setPapel: (state, papel) => {
      state.papel = papel;
    },
    setProducto: (state, producto) => {
      state.producto = producto;
    },
    setCuaderno: (state, cuaderno) => {
      state.cuaderno = cuaderno;
    }
  },
  actions: {},
  modules: {},
});
