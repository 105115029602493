import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EspiralColor from '../views/EspiralColor.vue'
import EspiralView from '../views/EspiralView.vue'
import PapelView from '../views/PapelView.vue'
import ContactoView from '../views/ContactoView.vue'
import ProductosView from '../views/ProductoView.vue'
import CuadernoView from '../views/CuadernoView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    props: true,
  },
  {
    path: "/espiralcolor/:id",
    name: "espiralcolor",
    component: EspiralColor,
  },
  {
    path: "/espiral",
    name: "espiral",
    component: EspiralView,
  },
  {
    path: "/papel",
    name: "papel",
    component: PapelView,
  },
  {
    path: "/producto",
    name: "producto",
    component: ProductosView,
  },
  {
    path: "/cuaderno",
    name: "cuaderno",
    component: CuadernoView,
  },
  {
    path: "/contacto",
    name: "conatacto",
    component: ContactoView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
