<template>
  <div>
    <cuaderno />
  </div>
</template>
<script>
import Cuaderno from "../components/CuadernoEscolare.vue"
// @ is an alias to /src

export default {
  name: "CuadernoView",
  components: {
   Cuaderno,
  },
   mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
h1 {
  padding-top: 10px;
}

</style>