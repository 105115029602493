<template>
  <div>
    <NavBar />
    <router-view />
    <FooterPrincipal />
  </div>
</template>
<script>
import FooterPrincipal from "./components/FooterPrincipal.vue";
import NavBar from "./components/NavBar.vue";
export default {
  components: {
    NavBar,
    FooterPrincipal,
  },
};
</script>
<style lang="scss">
body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}

.header-card img {
  border-radius: 8px 8px 0 0;
  max-height: 425px;
  height: 425px;
  width: 100%;
}
.categoria-card {
  margin-top: 1rem;
}
.categoria-card span {
  background: #25c4d8;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}
.card footer {
  padding: 0 1rem;
}
.button-card {
  text-align: center;
  margin: 1rem 1rem 1rem 0;
}

.card-wrap {
  display: flex;
  flex-flow: wrap;
}
.topmargin {
  margin-top: 70px;
}

@media (max-width: 576px) {
  article.card {
    max-width: 337px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
}

@media (min-width: 768px) {
  article.card {
    max-width: 310px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
}

@media (min-width: 992px) {
  article.card {
    max-width: 430px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
}

@media (min-width: 1200px) {
  article.card {
    max-width: 337px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }

}
</style>
