<template>
  <div class="container text-center topmargin">
    <h1>Productos</h1>
    <h6>El valor de los productos publicados NO tiene incluido el despacho.</h6>
    <h6>los valores publicados sin IVA.</h6>
    <div class="card-wrap">
      <article class="card" v-for="(producto, id) in producto" v-bind:key="id">
        <header class="header-card">
          <img v-bind:src="'img/producto/' + producto.img" alt="img" />
        </header>
        <footer class="footer-card">
          <div class="categoria-card">
            <div class="texto-card tipo">
              <h2>{{ producto.idcuaderno }} {{ producto.formato }}</h2>
              <h4>{{ producto.tipo }}</h4>
            </div>
            <div class="detalle">
              <h6>{{ producto.comment }}</h6>
            </div>
            <div class="text-left text-left-1">
              <h6>Peso : {{ producto.peso }}</h6>
              <h6>Tamaño : {{ producto.tamaño }}</h6>
              <h5>Valor : {{ producto.precio }} (+) IVA</h5>
              <h5 class="text-center">Comuniquese por WhatsApp</h5>
            </div>
            <div></div>
            <button class="button-card-mercado">
              <a
                :href="
                  'https://wa.me/56993129104/?text=Me interesa el ' +
                  producto.tipo +
                  ' modelo ' +
                  producto.formato
                "
                target="_blank"
                >WhatsApp</a
              >
            </button>
          </div>
        </footer>
      </article>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PapelLista",

  computed: {
    ...mapState(["producto"]),
  },
};
</script>

<style>
.detalle {
  height: 250px;
  margin-top: 20px;
}
.tipo {
  height: 125px;
}

.text-left-1 {
  margin-top: 150px;
  height: 200px;
}

.button-card-mercado {
  font-size: 125%;
  margin-top: 10px;
  border-radius: 25px;
  background: #62f367;
  color: rgb(88, 85, 85);
  margin-bottom: 15px;
  height: 40px;
  width: 130px;
}

/* @media (max-width: 576px) {

}
 */
@media (min-width: 768px) {
  .text-left-1 {
    margin-top: 230px;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .text-left-1 {
    margin-top: 150px;
    height: 200px;
  }
}

@media (min-width: 1200px) {
  .text-left-1 {
    margin-top: 150px;
    height: 200px;
  }
}
</style>
