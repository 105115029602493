<template>
  <div class="container text-center topmargin">
    <h1>Cuaderno y Croquera</h1>
    <h6>El valor de los productos publicados NO tiene incluido el despacho.</h6>
    <h6>los valores publicados sin IVA.</h6>

    <div class="card-wrap">
      <article class="card" v-for="(cuaderno, id) in cuaderno" v-bind:key="id">
        <header class="header-card">
          <img v-bind:src="'img/cuaderno/' + cuaderno.img" alt="img" />
        </header>
        <footer class="footer-card">
          <div class="categoria-card">
            <div class="texto-card texto-card-1 tipo">
              <h2>{{ cuaderno.formato }}</h2>
              <h3>{{ cuaderno.tipo }}</h3>
              <h4>{{ cuaderno.codigo }}</h4>
            </div>
            <div class="detalle">
              <h6>{{ cuaderno.comment }}</h6>
            </div>
            <div class="text-left">
              <h6>Peso : {{ cuaderno.peso }}</h6>
              <h6>Tamaño : {{ cuaderno.tamaño }}</h6>
              <h5>Valor : {{ cuaderno.precio }} (+) IVA</h5>
              <h5 class="text-center">Comuniquese por WhatsApp</h5>
            </div>

            <button class="button-card-mercado">
              <a
                :href="
                  'https://wa.me/56993129104/?text=Me interesa el ' +
                  cuaderno.tipo +
                  ' codigo : ' +
                  cuaderno.codigo
                "
                target="_blank"
                >WhatsApp</a
              >
            </button>
          </div>
        </footer>
      </article>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CuadernoEscolare",

  computed: {
    ...mapState(["cuaderno"]),
  },
};
</script>

<style>
.detalle {
  height: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.tipo {
  height: 125px;
}

.button-card-mercado {
  font-size: 125%;
  margin-top: 10px;
  border-radius: 25px;
  background: #62f367;
  color: rgb(88, 85, 85);
  margin-bottom: 15px;
  height: 40px;
  width: 130px;
}

@media (max-width: 576px) {
  .text-left {
    padding-top: 100px;
  }

  .texto-card-1 {
    margin-top: 30px;
    margin-bottom: 40px;
    height: 170px;
  }
}

/* @media (max-width: 576px) {

}
 */
@media (min-width: 768px) {
  .text-left {
    padding-top: 65px;
  }
}

@media (min-width: 1200px) {
  .text-left {
    padding-top: 60px;
  }
}
</style>
