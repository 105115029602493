<template>
  <div>
    <Productos />
  </div>
</template>

<script>
import Productos from "../components/Productos.vue";
// @ is an alias to /src

export default {
  name: "ProductoView",
  components: {
    Productos,
  },
   mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
h1 {
  padding-top: 10px;
}
</style>